// extracted by mini-css-extract-plugin
export var awsExpertImg = "SustainabilityMain-module--awsExpertImg--87506";
export var awsExpertImgDiv = "SustainabilityMain-module--awsExpertImgDiv--83ca2";
export var awsMainImage = "SustainabilityMain-module--awsMainImage--6189d";
export var businessImage = "SustainabilityMain-module--businessImage--feecf";
export var businessItem = "SustainabilityMain-module--businessItem--ee568";
export var businessItemImage = "SustainabilityMain-module--businessItemImage--50829";
export var businessItemMain = "SustainabilityMain-module--businessItemMain--dfc1c";
export var businessMain = "SustainabilityMain-module--businessMain--6e795";
export var businessText = "SustainabilityMain-module--businessText--73b10";
export var buttonClasses = "SustainabilityMain-module--buttonClasses--8ec9d";
export var carouselContainer = "SustainabilityMain-module--carouselContainer--657a8";
export var caseChallengeImage = "SustainabilityMain-module--caseChallengeImage--c9cec";
export var caseTestimonialImage = "SustainabilityMain-module--caseTestimonialImage--253c6";
export var contactButton = "SustainabilityMain-module--contactButton--a2b24";
export var heroBackgroundCircleLeft = "SustainabilityMain-module--heroBackgroundCircleLeft--d66ad";
export var heroBackgroundInnerCircleLeft = "SustainabilityMain-module--heroBackgroundInnerCircleLeft--dcb0e";
export var heroWhiteBlurCirce = "SustainabilityMain-module--heroWhiteBlurCirce--c79a6";
export var knowUs = "SustainabilityMain-module--knowUs--47cf3";
export var knowUsImage = "SustainabilityMain-module--knowUsImage--4eda9";
export var knowUsSubDiv = "SustainabilityMain-module--knowUsSubDiv--e0e65";
export var knowUsText = "SustainabilityMain-module--knowUsText--81225";
export var partners = "SustainabilityMain-module--partners--0a118";
export var partnersBenefits = "SustainabilityMain-module--partnersBenefits--41fce";
export var partnersBenefitsCarousel = "SustainabilityMain-module--partnersBenefitsCarousel--83883";
export var partnersBenefitsInner = "SustainabilityMain-module--partnersBenefitsInner--6729b";
export var partnersBenefitsItem = "SustainabilityMain-module--partnersBenefitsItem--59851";
export var partnersBenefitsItemBusiness = "SustainabilityMain-module--partnersBenefitsItemBusiness--1db70";
export var partnersBenefitsItemBusinessTop = "SustainabilityMain-module--partnersBenefitsItemBusinessTop--f3b42";
export var partnersBenefitsTech = "SustainabilityMain-module--partnersBenefitsTech--3e2a4";
export var partnersButtonsContainer = "SustainabilityMain-module--partnersButtonsContainer--fa4ce";
export var partnersMainSlider = "SustainabilityMain-module--partnersMainSlider--da17d";
export var singleBenefitTextBox = "SustainabilityMain-module--singleBenefitTextBox--7eb91";
export var singleCaseChallenge = "SustainabilityMain-module--singleCaseChallenge--25c14";
export var singleCaseContentBlock = "SustainabilityMain-module--singleCaseContentBlock--b80bd";
export var singleCaseImageBox1 = "SustainabilityMain-module--singleCaseImageBox1--d1f4b";
export var singleCaseSolution = "SustainabilityMain-module--singleCaseSolution--3c60d";
export var singleCaseTestimonial = "SustainabilityMain-module--singleCaseTestimonial--236a3";
export var singleCaseTextBox = "SustainabilityMain-module--singleCaseTextBox--62c44";
export var testimonialContent = "SustainabilityMain-module--testimonialContent--37da5";